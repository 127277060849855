import React from 'react';
import styled from 'styled-components';
import { colors, styles } from 'herald-fe-shared';

import Page from '~/layout/LayoutMarketing';
import LargeCTA from '~/components/LargeCTA';
import Image from '~/components/Image';
import Video from '~/images/screenshots/persona-creation.mp4';
import Opener, { USE_CASES } from '~/components/use-cases/Opener';
import UseCaseSection, {
  UseCaseScreenshot,
} from '~/components/use-cases/UseCaseSection';
import QuotesSection from '~/components/use-cases/QuotesSection';
import { WORKS_WITH, OTHER_APPS_SLUG } from '~/components/works-with/Opener';

const Styled = styled.div`
  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  .container__image {
    flex: 1 1 auto;
  }
  .container__text {
    flex: 0 0 50%;
  }
  .channels {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
  }
  .channels__channel {
    background: ${colors.GRAY_2(0.5)};
    padding: 1rem;
    border-radius: 5px;
  }
  .channels__channel label {
    color: ${colors.GRAY_3()};
  }
  .channels__channel__logos {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 1rem;
  }
  .channels__channel__logos__logo {
    width: 2rem;
    height: 2rem;
    margin: 0 0.25rem;
  }
  .quantify {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
  .quantify__screenshot__description {
    font-size: 1rem;
    line-height: 1.2rem;
    margin-top: 1rem;
    color: ${colors.GRAY_3()};
  }
  .quotes__title {
    text-align: center;
  }
  .quotes__grid {
    grid-template-columns: 1fr;
  }
  .quotes__grid .quote {
    max-width: 50%;
    margin: auto;
  }
  .quantify__persona-creation {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: ${styles.BOX_SHADOW};
  }

  video {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    margin-bottom: -10px;
  }
  @media screen and (max-width: 600px) {
    .filter-screenshot {
      min-width: 500px;
      overflow: visible;
    }
    .quantify {
      grid-template-columns: 1fr;
    }
    .quotes__grid .quote {
      max-width: none;
    }
    .quantify__persona-creation {
      width: calc(100vw + 4rem);
      margin-left: -2rem;
    }
  }
`;

const CHANNELS = {
  Email: [
    { id: 'front', logo: 'front-symbol', slug: WORKS_WITH.front.slug },
    { id: 'gmail', logo: 'gmail', slug: OTHER_APPS_SLUG + 'gmail' },
  ],
  Chat: [
    { id: 'intercom', logo: 'intercom', slug: WORKS_WITH.front.slug },
    { id: 'crisp', logo: 'crisp', slug: OTHER_APPS_SLUG + 'Crisp' },
    { id: 'slack', logo: 'slack', slug: WORKS_WITH.slackShared.slug },
  ],
  Text: [
    {
      id: 'openphone',
      logo: 'openphone-symbol',
      slug: OTHER_APPS_SLUG + 'OpenPhone',
    },
  ],
  'Social Media': [
    { id: 'twitter', logo: 'twitter' },
    { id: 'discord', logo: 'discord', slug: OTHER_APPS_SLUG + 'Discord' },
  ],
};

const PrioritizeCustomerNeeds: React.FC = () => {
  return (
    <Page title={USE_CASES[1].title}>
      <Styled>
        <Opener sectionIndex={1} />
        <UseCaseSection
          gray={true}
          title={
            <>Take a data-driven approach to quantifying&nbsp;user feedback.</>
          }
          description={
            <p>
              Herald dashboards let your team easily focus on problems within a
              specific product area, for instance, onboarding or billing.
            </p>
          }
          textStyle={{ maxWidth: '42rem' }}
          contentStyle={{
            maxWidth: '50rem',
            height: 'auto',
            marginBottom: '-8rem',
            overflow: 'visible',
          }}
          contentScreenshot={true}
          content={
            <UseCaseScreenshot className="filter-screenshot">
              <Image src="screenshots/filtered-by-label.png" />
            </UseCaseScreenshot>
          }
        />
        <UseCaseSection
          orientation="right"
          title={
            <>
              Enrich feedback
              <br className="drop-at-med" /> with customer data.
            </>
          }
          description={
            <p>
              Herald works with tools your team already uses. We provide
              integrations to seamlessly bring in customer data from Intercom,
              Segment, Hubspot, Salesforce, and more.
            </p>
          }
          content={
            <UseCaseScreenshot style={{ marginTop: '2rem' }}>
              <Image src="screenshots/contact-details.png" />
            </UseCaseScreenshot>
          }
          contentStyle={{ maxWidth: 'none' }}
          textStyle={{ flex: '0 0 40%', marginRight: '2rem' }}
        />
        <UseCaseSection
          orientation="left"
          title={<>Qualify feedback according to&nbsp;business goals.</>}
          description={
            <p>
              The combination of Herald Personas and the power of a robust
              slice-n-dice engine allows you to precisely understand what
              prospective customers want, what onboarding pains your new
              customers feel, or what additional features your largest customers
              need.
            </p>
          }
          content={
            <div className="quantify__persona-creation">
              <video
                autoPlay={true}
                loop={true}
                muted={true}
                playsInline={true}
              >
                <source src={Video} type="video/mp4" />
              </video>
            </div>
          }
          contentStyle={{ maxWidth: 'none' }}
          textStyle={{ marginTop: '4rem' }}
        />
        <QuotesSection sectionId="prioritize" />
        <LargeCTA title="Ready to take a data-driven approach to prioritizing customer needs?" />
      </Styled>
    </Page>
  );
};

export default PrioritizeCustomerNeeds;
